<template>
  <div>
    <div
       class="gavc-field"
       v-bind:class="{ 'is-error': $v.repreneurCedantEntrepriseCedeeSiren.$dirty ? $v.repreneurCedantEntrepriseCedeeSiren.$error : null  }">
      <label class="gavc-label" for="registration-step-2-repreneurCedantEntrepriseCedeeSiren-input">
          Le SIREN de l'entreprise est le
          <span class="gavc-required"> * </span>
          <i
            class="gavc-text-martinique gavc-icon gavc-icon-bulb"
            v-b-tooltip.hover.right="{ variant: 'light' }"
            title="Le SIREN est le numéro d’identification unique de l’entreprise du cédant identifié qui a été délivré par
            l’Insee lors de son immatriculation au registre national des entreprises. Si vous le cherchez, il apparaît sur toutes les factures et devis émis par son entreprise.
            Vous pouvez également vous connecter sur le site de l’Insee ou Societe.com pour le retrouver."
          >
          </i>
      </label>
      <div class="gavc-field">
        <input
          class="gavc-input"
          maxlength="11"
          @change="findCompanyBySiren()"
          @input="$v.repreneurCedantEntrepriseCedeeSiren.$touch()"
          id="registration-step-2-repreneurCedantEntrepriseCedeeSiren-input"
          placeholder="XXX XXX XXX"
          v-mask="'### ### ###'"
          v-model="maskedEntrepriseCedeeSiren"
        >
        <p v-if="$v.repreneurCedantEntrepriseCedeeSiren.$dirty ? $v.repreneurCedantEntrepriseCedeeSiren.$error : null " class="gavc-p gavc-text-rose">
          <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner le SIREN de l'entreprise à reprendre (9 chiffres)
        </p>
      </div>
    </div>

    <div v-if="hasFetchExistingCompany && !existingCedantCompany">
      <hr>
      <p class="gavc-chapo">
        <i class="gavc-icon gavc-icon-check" ></i> Cette entreprise n'existe pas encore sur Garance à vos côtés
      </p>
      <p class="gavc-p">
        En continuant, vous pouvez créer directement l'entreprise ainsi que le compte du cédant.<br>
        Celui-ci recevra alors un mail pour se connecter
      </p>
      <div
        class="gavc-field"
        v-bind:class="{ 'is-error': $v.repreneurCedantEntrepriseCedeeName.$dirty ? $v.repreneurCedantEntrepriseCedeeName.$error : null  }">
        <label class="gavc-label" for="registration-step-2-repreneurCedantEntrepriseCedeeName-group">
            La raison sociale de l'entreprise est
            <span class="gavc-required"> * </span>
            <i
              class="gavc-text-martinique gavc-icon gavc-icon-bulb"
              v-b-tooltip.hover.right="{ variant: 'light' }"
              title="La raison sociale correspond au nom officiel que votre cédant a déclaré lors de la création de son entreprise.
              Elle figure sur son extrait d’immatriculation et dans les statuts de sa société.  "
            >
            </i>
        </label>
        <input
          class="gavc-input"
          id="registration-step-2-repreneurCedantEntrepriseCedeeName-group"
          maxlength="250"
          placeholder="Raison sociale"
          @input="$v.repreneurCedantEntrepriseCedeeName.$touch()"
          type="text"
          v-model="repreneurCedantEntrepriseCedeeName"
        >
        <p v-if="$v.repreneurCedantEntrepriseCedeeName.$dirty ? $v.repreneurCedantEntrepriseCedeeName.$error : null " class="gavc-p gavc-text-rose">
          <i class="gavc-icon gavc-icon-cross" /> La raison sociale de l'entreprise du cédant doit comporter entre 2 et 250 caractères
        </p>
      </div>

      <hr>

      <p class="gavc-chapo">Informations sur le cédant :</p>

      <div
        class="gavc-field"
        v-bind:class="{ 'is-error': $v.repreneurCedantUserUsername.$dirty ? $v.repreneurCedantUserUsername.$error : null  }">
        <label class="gavc-label" for="registration-step-2-repreneurCedantUserUsername-group">
          Email :
          <span class="gavc-required"> * </span>
          <p class="gavc-legende"> Il s'agira de l'identifiant du cédant pour se connecter. Un mail lui sera envoyé. </p>
        </label>
        <input
          class="gavc-input"
          id="registration-step-2-repreneurCedantUserUsername-group"
          maxlength="150"
          @input="$v.repreneurCedantUserUsername.$touch()"
          @change="findUserByUsername"
          type="email"
          placeholder="jon@donym.fr"
          v-model="repreneurCedantUserUsername"
        >
        <p v-if="isExistingCedantUsername" class="mt-1 gavc-legende gavc-text-rose">
          Un utilisateur avec cet email existe déjà, veuillez choisir un autre email.
        </p>
        <p v-if="isExistingCedantUsername === false" class="mt-1 gavc-legende text-success">
          Cet identifiant est disponible
        </p>
        <p v-if="$v.repreneurCedantUserUsername.$dirty ? $v.repreneurCedantUserUsername.$error : null " class="gavc-p gavc-text-rose">
          <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner l'email du cédant
        </p>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-md-6">
          <div
            class="gavc-field"
            v-bind:class="{ 'is-error': $v.repreneurCedantUserFirstName.$dirty ? $v.repreneurCedantUserFirstName.$error : null  }">
            <label class="gavc-label" for="registration-step-2-repreneurCedantUserFirstName-group">
              Prénom
              <span class="gavc-required"> * </span>
            </label>
            <input
              class="gavc-input"
              id="registration-step-2-repreneurCedantUserFirstName-group"
              maxlength="128"
              @input="$v.repreneurCedantUserFirstName.$touch()"
              type="text"
              v-model="repreneurCedantUserFirstName"
            >
            <p v-if="$v.repreneurCedantUserFirstName.$dirty ? $v.repreneurCedantUserFirstName.$error : null " class="gavc-p gavc-text-rose">
              <i class="gavc-icon gavc-icon-cross" /> Le prénom du cédant doit comporter entre 2 et 128 caractères
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div
            class="gavc-field"
            v-bind:class="{ 'is-error': $v.repreneurCedantUserLastName.$dirty ? $v.repreneurCedantUserLastName.$error : null  }">
            <label class="gavc-label" for="registration-step-2-repreneurCedantUserLastName-group">
              Nom
              <span class="gavc-required"> * </span>
            </label>
            <input
              class="gavc-input"
              id="registration-step-2-repreneurCedantUserLastName-group"
              maxlength="128"
              @input="$v.repreneurCedantUserLastName.$touch()"
              type="text"
              v-model="repreneurCedantUserLastName"
            >
            <p v-if="$v.repreneurCedantUserLastName.$dirty ? $v.repreneurCedantUserLastName.$error : null " class="gavc-p gavc-text-rose">
              <i class="gavc-icon gavc-icon-cross" /> Le nom du cédant doit comporter entre 2 et 128 caractères
            </p>
          </div>
        </div>
      </div>

      <div class="gavc-field">
        <div class="gavc-radio-checkbox">
          <label class="gavc-label" for="registration-step-3-repreneurCedantIsPersonnePhysique">
              Le cédant détient l'entreprise à céder directement ou par le biais d’une société ?
              <span class="gavc-required"> * </span>
          </label>
          <div class="gavc-radio">
            <input :value="true" type="radio" id="repreneurCedantIsPersonnePhysique-input-1"
                   name="registration-step-3-repreneurCedantIsPersonnePhysique" v-model="repreneurCedantIsPersonnePhysique">
            <label class="gavc-label" for="repreneurCedantIsPersonnePhysique-input-1">
              Directement (personne physique ou société en cours de constitution)
              <i class="gavc-icon gavc-icon-check" ></i>
            </label>
            <br>
            <input :value="false" type="radio" id="repreneurCedantIsPersonnePhysique-input-2"
                   name="repreneurCedantIsPersonnePhysique-input-2" v-model="repreneurCedantIsPersonnePhysique">
            <label class="gavc-label" for="repreneurCedantIsPersonnePhysique-input-2">
              Par le biais d'une société constituée
              <i class="gavc-icon gavc-icon-check" ></i>
            </label>
          </div>
          <p v-if="$v.repreneurCedantIsPersonnePhysique.$dirty ? $v.repreneurCedantIsPersonnePhysique.$error : null " class="gavc-p gavc-text-rose">
            <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner cette information
          </p>
        </div>
      </div>

      <div v-if="repreneurCedantIsPersonnePhysique === 'false' || repreneurCedantIsPersonnePhysique === false">
        <h3 class="gavc-h3">La société du cédant</h3>
        <div
          class="gavc-field"
          v-bind:class="{ 'is-error': $v.repreneurCedantCompanyName.$dirty ? $v.repreneurCedantCompanyName.$error : null  }">
          <label class="gavc-label" for="registration-step-1-repreneurCedantCompanyName-group">
              La raison sociale de la société est
              <span class="gavc-required"> * </span>
          </label>
          <input
            class="gavc-input"
            id="registration-step-1-repreneurCedantCompanyName-group"
            maxlength="250"
            placeholder="Raison sociale"
            @input="$v.repreneurCedantCompanyName.$touch()"
            type="text"
            v-model="repreneurCedantCompanyName"
          >
          <p v-if="$v.repreneurCedantCompanyName.$dirty ? $v.repreneurCedantCompanyName.$error : null " class="gavc-p gavc-text-rose">
            <i class="gavc-icon gavc-icon-cross" /> Le nom de votre entreprise doit comporter entre 2 et 250 caractères
          </p>
        </div>
        <div
           class="gavc-field"
           v-bind:class="{ 'is-error': $v.repreneurCedantCompanySiren.$dirty ? $v.repreneurCedantCompanySiren.$error : null  }">
          <label class="gavc-label" for="registration-step-1-repreneurCedantCompanySiren-input">
              Le SIREN de la sociéé est
              <span class="gavc-required"> * </span>
          </label>
          <input
            class="gavc-input"
            maxlength="11"
            @input="$v.repreneurCedantCompanySiren.$touch()"
            id="registration-step-1-repreneurCedantCompanySiren-input"
            placeholder="XXX XXX XXX"
            v-mask="'### ### ###'"
            v-model="maskedCedantCompanySiren"
          >
          <p v-if="$v.repreneurCedantCompanySiren.$dirty ? $v.repreneurCedantCompanySiren.$error : null " class="gavc-p gavc-text-rose">
            <i class="gavc-icon gavc-icon-cross" /> Le SIREN doit être composé de 9 chiffres
          </p>
        </div>
      </div>
    </div>

    <div v-else-if="hasFetchExistingCompany && existingCedantCompany">
      <hr>
      <p class="gavc-chapo">
        <i class="gavc-icon gavc-icon-check" ></i> Cette entreprise existe déjà sur Garance à vos côtés !
      </p>
      <p class="gavc-p">
        Il s'agit de l'entreprise
        <strong>{{existingCedantCompany.name}}</strong> créée par le cédant
        <strong>{{existingCedantCompany.cedant.user.first_name}} {{existingCedantCompany.cedant.user.last_name}}</strong>.
      </p>
      <p class="gavc-p">
        En continuant votre inscription, une candidature pour cette offre sera automatiquement générée.
      </p>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, required, minLength, maxLength, numeric, requiredIf } from 'vuelidate/lib/validators'
import { createHelpers } from 'vuex-map-fields';

import { APIDisconnectedService } from '@/api/APIDisconnected'
import uxMixins from '@/mixins/uxMixins'

const { mapFields } = createHelpers({
  mutationType: 'updateFieldWithLocalStorage',
});

const apiDisconnectedService = new APIDisconnectedService()



export default {
  name: 'cedantInformation',
  data: function () {
    return {
      existingCedantCompany: null,
      hasFetchExistingCompany: false,
      isExistingCedantUsername: null,

      maskedEntrepriseCedeeSiren: '',
      maskedCedantCompanySiren: '',
    }
  },
  mixins: [
    uxMixins,
    validationMixin
  ],
  computed: {
    ...mapFields([
      'repreneur.cedant.entreprise_cedee.repreneurCedantEntrepriseCedeeName', 'repreneur.cedant.entreprise_cedee.repreneurCedantEntrepriseCedeeSiren',
      'repreneur.cedant.repreneurCedantIsPersonnePhysique',
      'repreneur.cedant.company.repreneurCedantCompanyName', 'repreneur.cedant.company.repreneurCedantCompanySiren',
      'repreneur.cedant.user.repreneurCedantUserFirstName', 'repreneur.cedant.user.repreneurCedantUserLastName',
      'repreneur.cedant.user.repreneurCedantUserUsername',
      'repreneur.existingEntrepriseCedeeSiren'
    ]),
    isInvalidInformation () {
      if (this.$v.$invalid) {
        return true
      }
      return false
    }
  },
  mounted () {
    this.scrollToTop()
    this.maskedEntrepriseCedeeSiren = this.repreneurCedantEntrepriseCedeeSiren
    this.maskedCedantCompanySiren = this.repreneurCedantCompanySiren
    if (this.repreneurCedantEntrepriseCedeeSiren.length === 9) {
      this.findCompanyBySiren()
    }
    this.$emit('invalidInformationChanged', this.$v.$invalid)
    this.$emit('isExistingCedantUsernameChanged', this.isExistingCedantUsername)
  },

  watch: {
    maskedEntrepriseCedeeSiren(value) {
      this.repreneurCedantEntrepriseCedeeSiren = value.replace(/ /g,'')
    },
    maskedCedantCompanySiren(value) {
      this.repreneurCedantCompanySiren = value.replace(/ /g,'')
    },
    "$v.$invalid": function() {
      this.$emit('invalidInformationChanged', this.$v.$invalid)
    },
    isExistingCedantUsername (value) {
      this.$emit('isExistingCedantUsernameChanged', value)
    },
    existingCedantCompany (value) {
      this.$emit('isExistingCedantCompanyChanged', value)
    }
  },
  validations: {
    repreneurCedantEntrepriseCedeeName: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required,
    },
    repreneurCedantEntrepriseCedeeSiren: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(9),
      numeric
    },

    repreneurCedantUserFirstName: {
      minLength: minLength(2),
      maxLength: maxLength(30),
      required,
    },
    repreneurCedantIsPersonnePhysique: {
      required
    },
    repreneurCedantUserLastName: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required,
    },

    repreneurCedantUserUsername: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required,
      email
    },
    repreneurCedantCompanyName: {
      required: requiredIf(function() {
        return this.repreneurCedantIsPersonnePhysique === false
      }),
      minLength: minLength(2),
      maxLength: maxLength(250)
    },
    repreneurCedantCompanySiren: {
      required: requiredIf(function() {
        return this.repreneurCedantIsPersonnePhysique === false
      }),
      minLength: minLength(9),
      maxLength: maxLength(9),
      numeric
    }
  },
  methods: {
    findCompanyBySiren () {
      if (this.repreneurCedantEntrepriseCedeeSiren.length !== 9) {
        return
      }
      apiDisconnectedService.findCompanyBySiren(this.repreneurCedantEntrepriseCedeeSiren)
      .then((result) => {
        if (result.data.company) {
          this.existingEntrepriseCedeeSiren = result.data.company.siren
        }
        else {
          this.existingEntrepriseCedeeSiren = null
        }
        this.existingCedantCompany = result.data.company
      })
      .catch((error) => {
        throw new Error('repreneurRegistrationStep2 findCompanyBySiren API Error : ' + String(error))
      })
      .finally(() => {
        this.hasFetchExistingCompany = true
      })
    },

    findUserByUsername () {
      if (this.$v.repreneurCedantUserUsername.$invalid) {
        return
      }
      apiDisconnectedService.findUserByUsername(this.repreneurCedantUserUsername)
      .then((result) => {
        this.isExistingCedantUsername = result.data.isUser
      })
      .catch((error) => {
        throw new Error('repreneurRegistrationStep2 findUserByUsername API Error : ' + String(error))
      })
    },
  }
}

</script>
